import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { LoginLayout } from '../layouts';
import { useForgotPasswordParam } from '../hooks';
import { api } from '../services';

const LoginPage = () => {
  const [data, setData] = useState({ login: null, forgotPassword: null });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const currentLang = useSelector(({ config }) => config.language);
  const openRecoveryPassword = useForgotPasswordParam();
  const { fetchLoginData, fetchForgotPasswordData } = api();

  useEffect(() => {
    let ignore = false;
    const fetchData = async ignore => {
      try {
        let loginResponse = null;
        let forgotPasswordResponse = null;
        loginResponse = await fetchLoginData(currentLang);
        forgotPasswordResponse = await fetchForgotPasswordData(currentLang);
        if (!ignore) {
          setData({
            forgotPassword: forgotPasswordResponse,
            login: loginResponse,
          });
        }
      } catch (e) {
        setError(e.message);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };
    fetchData(ignore);
    return () => {
      ignore = true;
    };
  }, [currentLang, fetchLoginData, fetchForgotPasswordData]);

  return (
    <LoginLayout
      data={data}
      loading={loading}
      error={error}
      openRecoveryPassword={openRecoveryPassword}
      currentLang={currentLang}
    />
  );
};

export default LoginPage;
